@use 'styles/colors';

.overlay {
    --modal-min-width: 17.1875rem;
}

.option {
    width: 2rem;
    height: 2rem;
}

.filter {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .filtered {
        background-color: colors.$primary-darker;
    }
}
