@use 'styles/colors';

.panel {
    opacity: 1;
    position: relative;
    z-index: 500;

    .loader {
        display: flex;
        position: fixed;

        width: 100%;
        height: 100%;

        background-color: colors.$base-white;
        opacity: 85%;

        z-index: 1000;
    }
}
