@use 'styles/colors';
@use 'styles/borders';
@use 'styles/components';

.table {
    overflow: auto;
    height: 100%;
    width: 100%;
    padding-right: 6px;

    table {
        table-layout: auto !important;
        border-collapse: separate;
        margin: 0;
        font-size: 14px;

        thead {
            position: sticky;
            top: 0;
            z-index: 20;

            th {
                white-space: nowrap;
                border-style: none;
                vertical-align: top;
                background-color: colors.$base-white;
            }
            tr {
                &.border {
                    th {
                        height: 1px;
                        padding: 0;
                        background-color: colors.$base;
                        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }

        td {
            border-style: none !important;
            white-space: pre-wrap;
            vertical-align: top;

            &.fixed {
                position: sticky;
                left: 0;
                z-index: 10;

                min-width: 8rem;
                max-width: 12.5rem;
                border-right-width: 1px;
                border-right-style: solid !important;
                border-right-color: colors.$base;
            }

            &.sorted {
                background-color: colors.$cool-accent-lightest;
            }
        }

        tr:nth-child(odd) {
            td {
                background-color: colors.$base-lightest;

                &.sorted {
                    background-color: colors.$cool-accent-lighter;
                }
            }
        }

        @extend %thin-bottom;
    }

    &.large {
        table {
            @extend %large;
            line-height: 1.6;
        }
    }

    &.medium {
        table {
            @extend %medium;
            line-height: 1.5;
        }
    }

    &.small {
        table {
            @extend %small;
            line-height: 1.4;
            tr {
                th, td {
                    padding: 0.25rem 0.5rem;
                }
                th:first-child, td:first-child {
                    padding-left: 1rem;
                }
            }
        }
    }
}
